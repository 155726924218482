import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      loading: false,
      busy: false,
      date_start: null,
      date_end: null,
      type: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      orderTypes: [
        { text: 'ค่าบริการ', value: 'service' },
        { text: 'ค่าขนส่ง', value: 'delivery' },
        { text: 'อื่น ๆ', value: 'other' }
      ],
      fields: [
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_tax', 
          label: 'เลขที่ถูกหัก ณ ที่จ่าย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'tax_no', 
          label: 'เลขประจำตัวผู้เสียภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'handler', 
          label: 'ผู้หัก ณ ที่จ่าย',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        },
        { 
          key: 'type', 
          label: 'ประเภท', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'nettotal', 
          label: 'ฐานภาษี', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'vat', 
          label: 'อัตราภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        },
        { 
          key: 'price', 
          label: 'จำนวนเงิน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_date: new Date(),
          doc_no: 'RV-201665156',            
          doc_tax: '5309-001',
          tax_no: '12345678910',
          handler: 'SangThai Ltd',
          type: 'ค่าขนส่ง',
          nettotal: 5000,
          vat: 3
        },
        {
          _id: '123457',
          doc_date: new Date(),
          doc_no: 'RV-201665157',            
          doc_tax: '5309-002',
          tax_no: '12345678911',
          handler: 'SangThai Ltd',
          type: 'ค่าบริการ',
          nettotal: 10000,
          vat: 3
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
